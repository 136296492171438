import { useApi } from 'lib/contexts/ApplicationState';
import { useEffect } from 'react';
import {
  ACTIONS, AuditTarget, AuditAction, EventData, ShareEventData,
  PostCardClickContext,
  CreatorCardClickContext, FavoritePostClickContext,
} from 'lib/types/events';
import { apiClient } from 'lib/apiClient';

export interface TrackedEvent {
  action: AuditAction;
  target: AuditTarget;
  targetId?: number | string;
  fields?: EventData;
  targetParent?: AuditTarget;
  targetParentId?: number;
}

/**
 * useEventTracking posts an event to the Gondola API that can be used for
 * tracking views, clicks, etc.
 * @param  {TrackedEvent} event - the event to track
 * @param  {UserState} [currentUser] - the current user (if any)
 */
const useEventTracking = (
  event: TrackedEvent,
) => {
  const API = useApi();

  useEffect(() => {
    API.trackEvent(event);
  }, []); // If currentUser changes (probably from undefined -> null or a User)
};

/**
 * useViewPostEvent tracks a view of a post
 * @param  {number} postId - the post ID that was viewed
 * @param  {EventData} [data] - any additional data, as key/value
 */
export const useViewPostEvent = (
  postId: number,
  data?: EventData,
) => {
  useEventTracking({
    action: ACTIONS.view,
    target: 'Posts',
    targetId: postId,
    fields: data,
  });
};

/**
 * useViewProfileEvent tracks a view of a profile
 * @param  {number} profileUserId
 * @param  {EventData} [data] - any additional data, as key/value
 */
export const useViewProfileEvent = (
  profileUserId: number,
  data?: EventData,
) => {
  useEventTracking({
    action: ACTIONS.view,
    target: 'Users',
    targetId: profileUserId,
    fields: data,
  });
};

/**
 * useClickSocialMediaLinkEvent tracks a click on a social link on a profile
 * @param  {string} networkName
 * @param  {number} profileUserId
 * @param  {UserState} [currentUser]
 * @param  {EventData} [data] - any additional data, as key/value
 */
export const trackClickSocialMediaLinkEvent = async (
  api: ReturnType<typeof apiClient>,
  networkName: string,
  profileUserId: number,
  data?: EventData,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'SocialLink',
    targetId: profileUserId,
    fields: { ...data, network: networkName },
  });
};

/**
 * trackClickContactEvent tracks a click on a creator's contact button
 * @param  {number} profileUserId
 * @param  {UserState} [currentUser]
 */
export const trackClickContactEvent = async (
  api: ReturnType<typeof apiClient>,
  profileUserId: number,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'ContactUser',
    targetId: profileUserId,
  });
};

/**
 * trackClickProfileEvent tracks a click on profile menu
 * @param  {number} profileUserId
 */
export const trackClickProfileEvent = async (
  api: ReturnType<typeof apiClient>,
  profileUserId: number,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'Profile',
    targetId: profileUserId,
  });
};

/**
 * trackClickUpgradeEvent tracks a click on a Pro upgrade banner/popup
 */
export const trackClickUpgradeEvent = async (
  api: ReturnType<typeof apiClient>,
  fields?: Record<string, any>,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'Upgrade',
    fields,
  });
};

/**
 * trackClickSignupEvent tracks a click on a signup button
 */
export const trackClickSignupEvent = async (
  api: ReturnType<typeof apiClient>,
  source: string,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'Signup',
    fields: { source },
  });
};

/**
 * trackClickSwitchToManager tracks a click on switch to manager
 * @param  {number} profileUserId
 */
export const trackClickSwitchToManager = async (
  api: ReturnType<typeof apiClient>,
  profileUserId: number,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'SwitchToManager',
    targetId: profileUserId,
  });
};

/**
 * trackClickSwitchToProfile tracks a click on switch to profile (from manager)
 * @param  {number} profileUserId
 */
export const trackClickSwitchToProfile = async (
  api: ReturnType<typeof apiClient>,
  profileUserId: number,
) => {
  await api.trackEvent({
    action: ACTIONS.click,
    target: 'SwitchToProfile',
    targetId: profileUserId,
  });
};

/**
 * trackClickExternalApplicationEvent tracks a click on a link to apply via external website
 * @param  {number} jobId
 * @param  {UserState} [currentUser]
 */
export const trackClickExternalApplicationEvent = async (
  api: ReturnType<typeof apiClient>,
  jobId: number,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'ExternalApplication',
    targetId: jobId,
  });
};

/**
 * trackClickInternalApplicationEvent tracks a click on a link to apply via external website
 * @param  {number} jobId
 * @param  {UserState} [currentUser]
 */
export const trackClickInternalApplicationEvent = async (
  api: ReturnType<typeof apiClient>,
  jobId: number,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'InternalApplication',
    targetId: jobId,
  });
};

/**
 * trackShareContentEvent tracks downloads/copies/shares of content
 * (replay cards, thank you cards, etc.)
 * @param  {ShareEventData} data
*/
export const trackShareContentEvent = async (
  api: ReturnType<typeof apiClient>,
  data: ShareEventData,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'ShareContent',
    fields: data,
  });
};

/**
 * Tracks when a post card is clicked
 */
export const trackClickPostCardEvent = async (
  api: ReturnType<typeof apiClient>,
  postId: number,
  context?: PostCardClickContext,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'PostCard',
    targetId: postId,
    fields: context ? { context } : undefined,
  });
};

/**
 * Tracks when a creator card or row is clicked
 */
export const trackClickCreatorCardEvent = async (
  api: ReturnType<typeof apiClient>,
  userId: number,
  context?: CreatorCardClickContext,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'CreatorCard',
    targetId: userId,
    fields: context ? { context } : undefined,
  });
};

/**
 * Tracks when a creator card or row is clicked
 */
export const trackClickAddFavoritePost = async (
  api: ReturnType<typeof apiClient>,
  postId: number,
  context?: FavoritePostClickContext,
) => {
  api.trackEvent({
    action: ACTIONS.click,
    target: 'FavoritePost',
    targetId: postId,
    fields: context ? { context } : undefined,
  });
};
