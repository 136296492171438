import {
  isFinite, map, isNull, some,
} from 'lodash';
import getConfig from 'next/config';
import { Media, Post } from 'lib/types';
import { Credit } from 'lib/types/credits';
import { User, UserState } from 'lib/types/users';
import { Team } from 'lib/types/teams';
import {
  roleNameToEmoji, NETWORKS, NO_YOUTUBE_EMBED, NETWORK_IDS,
} from './constants';
import { ErrorMsg } from './constants/errors';
import { ROLE_PRO_ONLY } from './constants/teams';

interface ConfigType {
  publicRuntimeConfig: {
    GONDOLA_ENV: 'production' | 'staging' | 'local';
    API_HOST: string;
  };
}

const TWO_WEEKS_AGO = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);

// GOTCHA: don't import the lib/api.js file here as it will cause local build issues when
// trying to call Next's getConfig();

export const ucFirst = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

/**
 * What is the current Gondola env - production, staging, or local?
 * This should return the right answer on the server and the client.
 * Falls back to local if it can't find the env.
 */
const currentEnv = () => {
  try {
    const { publicRuntimeConfig }: ConfigType = getConfig();
    return publicRuntimeConfig.GONDOLA_ENV;
  } catch (err) {
    return 'local';
  }
};

/**
 * Use this function to get the API host on the frontend.
 */
export function ApiHost() {
  try {
    const { publicRuntimeConfig }: ConfigType = getConfig();
    return publicRuntimeConfig.API_HOST;
  } catch (err) {
    return 'https://gondola.cc';
  }
}

/** is the Gondola env production? */
export const isProd = () => currentEnv() === 'production';

/** is the Gondola env staging? */
export const isStaging = () => currentEnv() === 'staging';

/** is the Gondola env local? */
export const isLocal = () => currentEnv() === 'local';

/*
Unbelievably to remove a timezone in js we have to splice the string using hardcoded indicies.
 */
export const formatIsoString = (isoString: string) => isoString.slice(0, 19).replace('T', ' ');

export const hasPermission = (permissions: any[], permissionId: number) => (
  !!permissions && permissions.some((perm) => perm.permission === permissionId));

// https://stackoverflow.com/a/9102270/4885710
export const getYoutubeVideoId = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }
  return undefined;
};

export const validateUsername = (username: string) => {
  if (username.length > 30) {
    return 'Your username must be shorter than 30 characters.';
  }
  const regEx = new RegExp(/^[\w.]+$/i);
  if (!regEx.test(username.trim())) {
    return 'Your username can only contain letters, numbers, . and _';
  }
  return '';
};

// Shamelessly taken from https://stackoverflow.com/a/2686098
/**
 * abbreviateNum converts numbers and number-like strings like:
 * - 1234 -> 1.2k
 * - 12345 -> 12k
 * - 12345676 -> 12.3m
 * - -150 -> -150
 * - 1234 -> +1.2k (use includePlus to add a plus sign to any non-negative number)
 * @param  {string|number} number
 * @param  {number} decPlaces
 * @param  {boolean} includePlus
 */
export const abbreviateNum = (
  number: string | number, decPlaces: number, includePlus = false,
) => {
  const addSign = (num: number) => {
    if (!includePlus || num < 0) {
      return '';
    }
    return '+';
  };

  let parsedNum = parseInt(`${number}`, 10);
  if (isFinite(parsedNum)) {
    // 2 decimal places => 100, 3 => 1000, etc
    const power = 10 ** decPlaces;

    // Enumerate number abbreviations
    const abbrev = ['k', 'm', 'b', 't'];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i -= 1) {
      // Convert array index to "1000", "1000000", etc
      const size = 10 ** ((i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= parsedNum) {
        // Here, we multiply by power, round, and then divide by power.
        // This gives us nice rounding to a particular decimal place.
        // We always round down so 12,999 views will return 12.9k or 12k
        // rather than 13k.
        parsedNum = Math.floor((parsedNum * power) / size) / power;

        // Handle special case where we round up to the next abbreviation
        if ((parsedNum === 1000) && (i < abbrev.length - 1)) {
          parsedNum = 1;
          i += 1;
        }

        // We are done... Add the letter for the abbreviation
        return `${addSign(parsedNum)}${parsedNum}${abbrev[i]}`;
      }
    }
    return `${addSign(parsedNum)}${parsedNum.toString()}`;
  }
  return '—';
};

export const isLoggedIn = (user: UserState) => !isNull(user);

/** The user is a true PRO, either free or with a subscription or from a team */
export const userIsPro = (user: UserState) => !!user && (
  user.isPro || user.isFreePro || user.isTeamPro);

/** The user is an admin and can do admin-type things, like edit other users
 *  or edit any post/tag/credit.
 */
export const userIsAdmin = (user: UserState) => !!user && user.isAdmin;

/** The user is a beta user and can access some new features  */
export const userIsBeta = (user: UserState) => !!user && user.isBeta;

/** The user is a beta user OR is an admin. Sometimes used for feature rollouts */
export const userIsAdminOrBeta = (user: UserState) => !!user && (user.isBeta || user.isAdmin);

/** The user is an O.G. or admin or PRO and gets access to PRO features like importing and
 *  exporting posts. These users do not get access to the PRO badge or lists, though.
 */
export const userIsProOrAdminOrOG = (
  user: UserState,
) => !!user && (user.isPro || user.isAdmin || user.isOG || user.isFreePro || user?.isTeamPro);

/** The user is an admin or PRO and gets access to all PRO features */
export const userIsProOrAdmin = (
  user: UserState,
) => !!user && (user.isPro || user.isAdmin || user.isFreePro || user.isTeamPro);

/** The user is a full member of a team */
export const userIsPaidTeamMember = (
  user: UserState, teams?: Team[],
) => !!user && (!!user.hasPaidTeam || !!teams?.find(teamIsPaid));

/** The user is on a team, but might not be a paying team */
export const userIsTeamMember = (
  user: UserState,
) => !!user && !!user.teams && user.teams.length > 0;

/** The user is on a paid Teams plan (not Starter) */
export const userIsPaidFullTeamMember = (
  user: UserState, teams?: Team[],
) => !!user && !!teams?.find(teamIsPaidFullTeamsPlan);

/**
 * The teams that the user is a member of
 * These teams may not be paid (yet)!
 **/
export const userViewableTeams = (
  user: UserState,
) => user?.teams?.filter((team) => team.memberStatus === 'active' && team.memberRole !== ROLE_PRO_ONLY);

export const userHasSubscription = (
  user: User,
) => Number(user?.subscriptions?.length) > 0;

export const userHasAnalyticsTrial = (
  teams?: Team[],
) => teams?.some((team) => team.analyticsTrial);

export const userIsProfileOwner = (user: UserState, profileUser: User) => (
  !!user && profileUser && user.id === profileUser.id
);

/** Checks to see if the profile is a managedAccount
 * and if the current user is a team manager for that account
*/
export const userCanManageAccount = (user: UserState, profileUser: User) => (
  some(user?.managedAccounts || [], (account) => account.accountId === profileUser?.id)
);

export const creditsToText = (credits: Credit[]) => map(credits, (credit) => {
  if (credit.userId === -1 || !credit.role?.name || credit.isPrivate) {
    return '';
  }
  const emoji = roleNameToEmoji[credit.role.name];
  const role = emoji ? `${credit.role.name} ${emoji}` : credit.role.name;
  return `${credit.user?.name} (${role})`;
});

export const serialize = (data: any) => {
  if (!data) {
    return null;
  }
  return JSON.parse(JSON.stringify(data));
};

const regex = /[\u0300-\u036f\u3040-\u309F\u30A0-\u30FF\uff00-\uffef\u1100-\u11ff\u4e00-\u9fff/]/g;

export function slugify(str: string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str.toString()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const cleanUp = (str: string) => {
  if (!str) {
    return '';
  }
  return slugify(str.normalize('NFD').replace(regex, ''));
};

// A minimal interface for the fields we need to build a post slug
export interface PostForUrl {
  id: number;
  network: number;
  account: string;
}

export const postToSlug = (post: PostForUrl) => {
  const { id, account } = post;
  const network = NETWORKS[post.network];
  if (network?.name) {
    return `${id}-${cleanUp(account)}-${network.name}`;
  }
  return `${id}-${cleanUp(account)}`;
};

export const buildPostPath = (post: PostForUrl) => `/posts/${postToSlug(post)}`;

export const buildPostUrl = (post: PostForUrl) => `${ApiHost()}${buildPostPath(post)}`;

export const isYouTubeEmbedDisabled = (account: string) => (
  NO_YOUTUBE_EMBED.includes((account || '').toLowerCase())
);

const postTypes = {
  1: 'Video',
  2: 'Gif',
  3: 'Image',
  4: 'Text',
};

const getPostTypeText = (postType: number | null) => {
  if (postType) {
    return `${(postTypes as any)[postType]} post`;
  }
  return 'Post';
};

export const createAltTextForPosts = (post: Post) => `${getPostTypeText(post.type)} by @${post.account} on ${NETWORKS[post.network].title}`;

export const shouldPostBeMarkedDead = (network: number, errorMsg: string) => {
  // twitter msg: Could not find tweet with id: [id]
  // ig msg: Couldn't getPostData for [url]
  if (network === NETWORK_IDS.twitter && errorMsg.match(/Could not find tweet with id/i)) {
    return true;
  }
  // TODO(Jordan): Fix the false positives
  // if (network === NETWORK_IDS.instagram && errorMsg.match(/Post not found/i)) {
  //   return true;
  // }

  return false;
};

/**
 * Remove www. and trailing slashes from urls
 */
export function simplifyUrl(url: string) {
  return url
    .replace('www.', '')
    .replace(/\/$/, '');
}

export const postingAccountToUrl = (network: number, account: string) => {
  try {
    const alreadyUrl = new URL(account);
    return alreadyUrl.href;
  } catch (err) {
    // noop
  }

  const networkDetails = NETWORKS[network];
  if (!networkDetails) {
    return '';
  }
  const baseUrl = networkDetails.profileBaseUrl;
  const atSign = networkDetails.usesAtSign ? '@' : '';
  return `${baseUrl}${atSign}${account.trim()}`;
};

/**
 * Sometimes users send us posting accounts that are actually urls containing their username.
 * In some cases we can parse the url to extract the username.
 *
 * Returns the original accountUrl if any errors are encountered.
 */
export function extractUsernameFromPostingAccountUrl(network: number, accountUrl: string) {
  const url = new URL(simplifyUrl(accountUrl));
  const networkDetails = NETWORKS[network];
  if (!networkDetails) {
    throw new Error('Invalid network');
  }
  const urlPieces = url.href.split('/');
  if (!urlPieces?.length) {
    throw new Error(ErrorMsg.INVALID_URL);
  }
  const possibleUsername = urlPieces[urlPieces.length - 1];
  if (new URL(postingAccountToUrl(network, possibleUsername)).href === url.href) {
    return possibleUsername;
  }
  throw new Error('Could not extract username from accountUrl');
}

export function extractSourceIdFromMediumGuid(guid: string) {
  return guid.replace('https://medium.com/p/', '');
}

export function defaultBlogPostSlug(guid: string, title: string) {
  const sourceId = extractSourceIdFromMediumGuid(guid);
  return `${sourceId}-${slugify(title).toLowerCase()}`;
}

export function isRequestFromGondolaApp(req: any) {
  const userAgent = req?.headers?.['user-agent'];
  if (userAgent && userAgent.match(/GondolaApp/)) {
    return true;
  }
  return false;
}

/**
 * Return a username without its leading @ sign (if it has one) */
export function trimLeadingAtSign(username: string) {
  if (!username) {
    return undefined;
  }
  if (!username.startsWith('@')) {
    return username.trim();
  }
  return username.slice(1).trim();
}

export function looksLikeUrl(maybeUrl: string) {
  const urlRegex = /www|http|\.com/;
  return urlRegex.test(maybeUrl);
}

/* firebase https://firebase.google.com/docs/reference/security/database/regex */
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/ig;

interface JobForUrl {
  id: number;
  account: User;
  name: string;
}

export const jobToSlug = (job: JobForUrl) => `${job.id}-${cleanUp(job.account.username)}-${cleanUp(job.name)}`;

export const buildJobPath = (job: JobForUrl) => `/jobs/${jobToSlug(job)}`;

export function coalesceNull<T>(token: T): T {
  return token;
}

/**
 * Converts a sentence-like string to a file name using _
 */
export function stringToFilename(str: string) {
  return slugify(str)
    .replaceAll('-', '_')
    .toLowerCase();
}

export function truncate(str: string, max: number) {
  if (!str) return '';

  return str.length > max ? `${str.substring(0, max)}...` : str;
}

/**
 * Builds path for jobs list tab ie /teams/4/jobs
 */
export const buildListJobsPath = (accountId?: number, teamId?: number) => `/teams/${teamId}/jobs`;

/**
 * Builds path for creators search filtered to job applicants ie /creators?list=applicants~123
 */
export const buildJobApplicantsListPath = (listName: string, publicId: string) => `/creators?list=${encodeURIComponent(listName)}~${publicId}`;

/**
 * A simple pluralize function.
 *
 * @param word - The word to pluralize
 * @param count - The count of the word
 * @param pluralWord - The plural form of the word (if it's not just adding an 's')
 */
export function pluralize(word: string, count?: number, pluralWord?: string) {
  return count === 1 ? word : pluralWord || `${word}s`;
}

/**
 * Cuts off the string after the given length.
 * This is safer than a plain slice because it handles multi-byte characters.
 */
export function safeSlice(str: string | undefined, start = 0, length: number) {
  return [...(str || '')]
    .slice(start, length)
    .join('');
}

export const getFileType = (url: string): 'video' | 'image' | undefined => {
  const extension = url.split('.').pop()?.toLowerCase();
  if (!extension) return undefined;

  const videoExtensions = ['mp4', 'mov'];

  if (videoExtensions.includes(extension)) {
    return 'video';
  }
  return 'image';
};

export const firstValidMediaUrl = (media: Media[]): string | undefined => {
  const validMedia = media.find((m) => m.imageUrl || m.videoUrl);
  return validMedia?.imageUrl || validMedia?.videoUrl;
};

/**
 * Returns true if the team is a paid team of any kind
 */
export const teamIsPaid = (team?: Team) => team?.status === 'active'
  && (team?.isExternalInvoice || team?.subscription?.status === 'active');

/**
 * Returns true if the team is a paid team and is a 'teams' plan
 */
export const teamIsPaidFullTeamsPlan = (team?: Team) => teamIsPaid(team) && team?.plan === 'teams';

/**
 * Returns true if the team is a paid team and is at least a 'starter' plan
 */
export const teamIsPaidStarterPlan = (team?: Team) => teamIsPaid(team)
  && (team?.plan === 'starter' || team?.plan === 'teams');

/**
 * Returns true if the user only has a trial subscription and it's less than 14 days old.
 * We use the createdAt date to check if the trial is less than 14 days old as a hack
 * for users that have had referral extensions via free trial.
 */
export const userHasTrialSubscription = (user?: User) => !!user?.subscriptions?.length
  && user?.subscriptions.every((s) => s.status === 'trialing'
    && new Date(s.createdAt) > TWO_WEEKS_AGO);
