import Link from 'next/link';
import { NetworkIcon } from 'elements/NetworkIcon';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';

const getCurrentYear = () => new Date().getFullYear();
const demoUrl = 'https://form.asana.com/?k=-QbKTiVfal1caZTC61bYfg&d=522258131816821';

export const Footer = () => {
  const currentUser = useCurrentUser();
  const API = useApi();

  const signInOrInvite = currentUser
    ? <Link href="/invite" className="hover:text-teal">Invite a friend</Link>
    : <Link href="/signin" onClick={() => trackClickSignupEvent(API, 'footer-sign-in')} className="hover:text-teal">Sign In</Link>;
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8 text-center md:text-left">
          <div>
            <div className="flex space-x-4 justify-center md:justify-start">
              {/* Social Media Icons */}
              <span>
                <Link href="https://www.instagram.com/ongondola">
                  <NetworkIcon network={2} iconColor="light" />
                </Link>
              </span>
              <span>
                <Link href="https://www.twitter.com/ongondola">
                  <NetworkIcon network={1} iconColor="light" />
                </Link>
              </span>
              <span>
                <Link href="https://www.tiktok.com/@ongondola">
                  <NetworkIcon network={5} iconColor="light" />
                </Link>
              </span>
              <span>
                <Link href="https://www.linkedin.com/company/ongondola">
                  <NetworkIcon network={8} iconColor="light" />
                </Link>
              </span>
            </div>
          </div>
          <div>
            <h3 className="font-bold mb-4"><Link href="https://on.gondola.cc/creatives" className="hover:text-teal">For Creatives</Link></h3>
            <ul className="space-y-2">
              <li>{signInOrInvite}</li>
              <li><Link href="/explore" className="hover:text-teal">Explore</Link></li>
              <li><Link href="/jobs" className="hover:text-teal">Jobs</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4"><Link href="https://on.gondola.cc/business" className="hover:text-teal">For Businesses</Link></h3>
            <ul className="space-y-2">
              <li><Link href="/teams" className="hover:text-teal">Get Started</Link></li>
              <li><Link href={demoUrl} className="hover:text-teal">Book a Demo</Link></li>
              <li><Link href="/creators" className="hover:text-teal">Creator Search</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><Link href="/blog" className="hover:text-teal">Blog</Link></li>
              <li><Link href="https://help.gondola.cc" className="hover:text-teal">Help Center</Link></li>
              <li><Link href="/downloads" className="hover:text-teal">Downloads</Link></li>
              <li><Link href="https://status.gondola.cc" className="hover:text-teal">Status</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold mb-4">Company</h3>
            <ul className="space-y-2">
              <li><Link href="/about" className="hover:text-teal">About</Link></li>
              <li><Link href="/ongondola/jobs" className="hover:text-teal">Careers</Link></li>
              <li><Link href="/sportssummit25" className="hover:text-teal">Sports Summit 2025</Link></li>
              <li><Link href="https://merch.gondola.cc" className="hover:text-teal">Merch</Link></li>
              <li><Link href="/contact" className="hover:text-teal">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 text-sm text-gray-400 flex flex-wrap md:flex-row gap-4 justify-center md:justify-start w-full">
          <Link href="/">
            <img src="/Logo_White.png" alt="Gondola" className="max-w-[24px] max-h-[24px]" />
          </Link>
          <p>
            ©
            {' '}
            {getCurrentYear()}
            {' '}
            Gondola
          </p>
          <p><Link href="/privacy" className="hover:text-teal">Privacy Policy</Link></p>
          <p><Link href="/terms" className="hover:text-teal">Terms of Service</Link></p>
        </div>
      </div>
    </footer>
  );
};
