import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { DEBOUNCE_TIME } from 'lib/constants';
import router from 'next/router';
import {
  debounce, isEmpty, isNil,
} from 'lodash';
import SearchRounded from '@mui/icons-material/SearchRounded';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import { WorkOutline, Place } from '@mui/icons-material';
import {
  TextField, Avatar, Autocomplete,
} from '@mui/material';
import { isValidUrl } from 'lib/urls/urls';
import { TagValueIcon } from 'components/TagValueSearch/TagValueIcon';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useSignUpModal } from 'components/SignUpModal';
import { SearchResult } from 'lib/types/globalSearch';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';
import { LinkTrackingAlert } from './LinkTrackingAlert';
import { formatPostResult, formatResults } from './utils';

interface Props {
  label?: string;
  onSearch?: () => void;
  trackOnly?: boolean;
}

export const globalSearchCopy = 'Track a link. Look for a creator/brand.';

export const GlobalSearch = ({ label, onSearch, trackOnly = false }: Props) => {
  const [urlToTrack, setUrlToTrack] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<SearchResult[]>([]);

  const [alertMessage, setAlertMessage] = useState<string | undefined>();
  const [alertUrl, setAlertUrl] = useState<string | undefined>();

  const isFetching = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const API = useApi();
  const currentUser = useCurrentUser();
  const { showSignUpModal } = useSignUpModal();

  const onHandleChange = (r: SearchResult) => {
    if (r && !isEmpty(r.pageUrl)) {
      router.push(r.pageUrl);
      if (onSearch) onSearch();
      setInputValue('');
    }
  };

  const trackPost = useMemo(
    () => debounce(async (input, callback) => {
      try {
        const isTracking = isValidUrl(input);

        if (!isTracking) {
          if (trackOnly) {
            return null;
          }

          // If user type the symbol, don't search yet
          if (input === '#' || input === '@') {
            return null;
          }
          isFetching.current = true;
          const searchResults = await API.getTopSearch(input);
          const formattedResults = formatResults(searchResults);
          isFetching.current = false;
          callback(formattedResults);
          return null;
        }

        // setUrlToTrack when we try to fetch a post so the 'tracking a link' text is used
        setUrlToTrack(input);

        const post = await API.getPostByUrl(input);
        const isNewPost = isNil(post);

        if (isNewPost && currentUser) {
          setUrlToTrack(input);
          callback(null);
          return null;
        }

        if (isNewPost && !currentUser) {
          trackClickSignupEvent(API, 'global-search-add-post');
          showSignUpModal('That link doesn\'t exist in Gondola yet. Sign up or sign in to add it!');
          callback(null);
          return null;
        }
        if (!isNewPost) {
          setUrlToTrack('');
          const formattedPost = formatPostResult({
            id: post.id,
            model: 'posts',
            score: 10,
            account: post.account,
            caption: post.caption,
            thumbnailUrl: post.thumbnailUrl,
          });
          callback([formattedPost]);
          return null;
        }
        return null;
      } catch (error) {
        isFetching.current = false;
        return null;
      }
    }, DEBOUNCE_TIME),
    [currentUser],
  );

  useEffect(() => {
    isFetching.current = true;
    let active = true;
    if (isEmpty(inputValue)) {
      setOptions([]);
      return undefined;
    }

    trackPost(inputValue, (results: SearchResult[]) => {
      isFetching.current = false;
      if (active) {
        if (results) {
          setOptions(results);
          return;
        }
        setOptions([]);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, trackPost]);

  // Effect that waits on setting urlToTrack so we can display proper messaging in UI.
  useEffect(() => {
    if (currentUser && urlToTrack) {
      (async () => {
        try {
          const post = await API.createPostByUrl(urlToTrack);
          setAlertMessage(undefined);
          setAlertUrl(undefined);
          const results = formatPostResult({
            id: post.id,
            model: 'posts',
            score: 10,
            account: post.account,
            caption: post.caption,
            thumbnailUrl: post.thumbnailUrl,
          });
          setOptions([results]);
        } catch (error: any) {
          setAlertMessage(error?.message);
          setAlertUrl(urlToTrack);
          setOptions([]);
          setInputValue('');
        }
        setUrlToTrack('');
      })();
    }
  }, [urlToTrack]);

  const renderOptionImg = (option: SearchResult) => {
    switch (option.modelName) {
    case 'users':
      return (<Avatar alt={option.label} src={option.imgUrl} />);
    case 'tagValues':
      return (<div className="mr-6"><TagValueIcon tagTypeId={option.tagTypeId || 0} fontSize="large" color="secondary" /></div>);
    case 'posts':
      return (<Avatar variant="square" alt={option.label} src={option.imgUrl} />);
    case 'role':
      return (<div className="mr-6"><AssignmentInd fontSize="large" color="secondary" /></div>);
    case 'jobs':
      return (<div className="mr-6"><WorkOutline fontSize="large" color="secondary" /></div>);
    case 'locations':
      return (<div className="mr-6"><Place fontSize="large" color="secondary" /></div>);
    default:
      return (<Avatar alt={option.label} src={option.imgUrl} />);
    }
  };

  const noOptionText = () => {
    if (urlToTrack) {
      return 'Tracking link, sit tight!';
    }
    if (trackOnly) {
      return 'Please enter a valid url';
    }

    if (isFetching.current) {
      return 'Searching...';
    }
    return 'No results found. Paste a url to import or search for a creator, tag, role, job, or location.';
  };

  return (
    <>
      <div className="relative">
        <Autocomplete
          className="mx-auto"
          noOptionsText={noOptionText()}
          getOptionLabel={(option) => option.label}
          options={options}
          includeInputInList
          autoHighlight
          clearOnBlur={false}
          blurOnSelect
          clearOnEscape
          open={inputValue.length >= 1}
          disableClearable
          onChange={(_, value) => { if (value) onHandleChange(value); }}
          filterOptions={(theOptions) => theOptions}
          filterSelectedOptions={false}
          inputValue={inputValue}
          size="small"
          onInputChange={(_, newInputValue, reason) => {
            if (reason === 'reset') {
              // If the input is being reset, clear the inputValue
              setInputValue('');
            } else {
              setInputValue(newInputValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              className="bg-hintgray rounded-lg"
              {...params}
              label={label || globalSearchCopy}
              inputRef={inputRef}
              fullWidth
              variant="filled"
              InputProps={{
                className: 'rounded-lg',
                ref: params.InputProps.ref,
                disableUnderline: true,
                endAdornment: (
                  <div className="absolute text-mediumgray right-4 top-3">
                    <SearchRounded data-testid="search-icon" />
                  </div>
                ),
              }}
              InputLabelProps={{
                sx: { maxWidth: 'calc(100% - 48px)', paddingTop: '2px' },
              }}
              disabled={urlToTrack.length > 0}
              data-testid="search-input"
            />
          )}
          renderOption={
            (props, option: SearchResult) => (
              <li {...props} key={option.id}>
                <div className="flex w-full gap-2" data-testid={`link-${option.modelName}-${option.label}`}>
                  <div className="w-10">
                    {renderOptionImg(option)}
                  </div>
                  <div>
                    <div className="font-bold">
                      {option.label}
                    </div>
                    <div className="text-mediumgray text-sm">
                      {option.subLabel}
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        />
        {alertUrl && alertMessage && (
          <LinkTrackingAlert
            url={alertUrl}
            message={alertMessage}
            onClose={() => setAlertMessage('')}
          />
        )}
      </div>
    </>
  );
};
